export const logsInitialData = {
  total: {
    value: 0,
  },
  hits: [],
};

export default {
  data() {
    return {
      data: Object.assign({}, logsInitialData),
      loading: false,
      total: 0,
    };
  },
  created() {
    this.onFetchData();
  },
  methods: {
    onFetchData(params) {
      this.loading = true;
      this.fetchLogs(params)
        .then((resp) => {
          this.data = resp.hits;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
