export default function formatAppUrl(domain) {
  const regex = new RegExp(
    "^(?:http(s)?:\\/\\/)[\\w.-]+(?:[\\w\\.-]+)+[\\w\\-\\._~:/?#[\\]@!\\$&'\\(\\)\\*\\+,;=.]+$"
  );

  let appUrl = (regex.test(domain) ? domain : "https://" + domain).trim();

  if (appUrl[appUrl.length - 1] !== "/") appUrl += "/";

  return appUrl;
}
