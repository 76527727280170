/**
 * Method for map object by keys into array.
 *
 * @param {Object} object
 * @param {Array<string>} keys
 * @param {{ [key: string]: string }} linkKeys
 */
function mapperField(object, keys = [], linkKeys = {}) {
  return Object.keys(object)
    .map((key) => {
      let item;

      if (keys.indexOf(key) > -1) {
        item = {
          name: key,
        };

        if (linkKeys[key] !== undefined) {
          item.value = `${object[key]}`.link(`${linkKeys[key]}${object[key]}`);
        } else {
          item.value = object[key];
        }
      }

      return item;
    })
    .filter((item) => item !== undefined);
}

export { mapperField };
