<template>
  <div>
    <div class="container__search">
      <el-form class="logs-table__form" inline>
        <el-input v-model="form.query" :disabled="loading" placeholder="Поиск..." clearable />
        <el-date-picker
          v-model="form.date"
          :disabled="loading"
          format="dd.MM.yyyy"
          value-format="yyyy-MM-dd"
          type="date"
          placeholder="Дата"
        />
        <el-button-group class="logs-table__actions">
          <el-button
            tabindex="1"
            :loading="loading"
            :disabled="!paramsHasChanged"
            type="primary"
            icon="el-icon-search"
            @click="onSubmit"
          >
            Найти
          </el-button>
          <el-button
            :loading="loading"
            :disabled="!paramsHasSetting"
            type="danger"
            icon="el-icon-refresh-left"
            @click="onReset"
          />
        </el-button-group>
      </el-form>
    </div>
    <el-table v-loading="loading" class="list" :data="logs">
      <el-table-column label="Событие">
        <template slot-scope="scope">
          <div class="container__action">
            <span>{{ scope.row._source.log_dt }}</span>
            <span>{{ scope.row._source.action_v2 }}</span>
            <span>{{ scope.row._source.editor_user }}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="Результат изменения">
        <template slot-scope="scope">
          <div class="container__action">
            <ul>
              <li v-for="item in scope.row.areas" :key="item.name">
                {{ item.name }}:
                <span v-html="item.value" />
              </li>
            </ul>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      :total="data.total.value"
      :page-count="pageCount"
      hide-on-single-page
      layout="prev, pager, next"
      @current-change="changePage"
    />
  </div>
</template>
<script>
import { format } from "date-fns";
import ru from "date-fns/locale/ru";
import { NAME_ACTION } from "@/core/helpers/const";
import { mapperField } from "@/core/helpers/mapFieldFromObjectAndCreateLinkToArray";
import { logsInitialData } from "@/core/components/ElasticTableLogs/logsMixin";

const initialParams = {
  query: "",
  date: null,
  page: 1,
};

const initialForm = {
  query: "",
  date: null,
};

export default {
  name: "ElasticTableLogs",
  props: {
    data: {
      type: Object,
      default: () => Object.assign({}, logsInitialData),
    },
    keys: {
      type: Array,
      default: () => [],
    },
    linkKeys: {
      type: Object,
      default: () => {},
    },
    loading: {
      type: Boolean,
    },
  },
  data() {
    return {
      params: Object.assign({}, initialParams),
      form: Object.assign({}, initialForm),
    };
  },
  computed: {
    logs() {
      return this.data.hits.map((item) => {
        item._source.log_dt = format(new Date(item._source.log_dt), "dd MMMM yyyy H:mm", {locale: ru});
        item._source.id_action = NAME_ACTION[item._source.id_action - 1];
        let areas = [];

        areas = mapperField(item._source, this.keys, this.linkKeys);
        return { ...item, areas };
      });
    },
    paramsHasChanged() {
      const params = Object.keys(this.form);
      return params.some((key) => this.form[key] !== this.params[key]);
    },
    paramsHasSetting() {
      const params = Object.keys(initialParams);
      return params.some((key) => this.params[key] !== initialParams[key]);
    },
    pageCount() {
      return Math.ceil(this.data.total.value / 10);
    },
  },
  methods: {
    onSubmit() {
      Object.assign(this.params, this.form, {
        page: 1,
      });
      this.onFetch();
    },
    onReset() {
      this.params = Object.assign({}, initialParams);
      this.form = Object.assign({}, initialForm);
      this.onFetch();
    },
    changePage(page) {
      this.params.page = page;
      this.onFetch();
    },
    onFetch() {
      this.$emit("fetch", this.params);
    },
  },
};
</script>
<style lang="scss">
.list,
.list__pagination {
  margin: 20px 0;
}
.list__pagination {
  text-align: center;
}
.container__action {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.container__search {
  display: flex;
  flex-direction: row;
}

.logs-table {
  &__form {
    max-width: unset;
    width: 100%;
    display: flex;
    & > * {
      margin-right: 10px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
  &__actions {
    display: flex;
  }
}
</style>
