<template>
  <el-input id="search" v-model="value" placeholder="Поиск" autofocus @keyup.native.enter="search" :clearable="clearable">
    <el-button id="btn-search" slot="append" icon="el-icon-search" @click="search" />
  </el-input>
</template>

<script>
export default {
  name: "Search",
  props: {
    query: { type: String, default: "" },
    clearable: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      value: this.$props.query,
    };
  },
  watch: {
    query() {
      this.value = this.$props.query;
    },
  },
  methods: {
    search() {
      this.$emit("update:query", this.value);
      this.$emit("search", this.value);
    },
  },
};
</script>
